import React from 'react';
import iwocaPageStyles from '../templates/uk/iwocaPage.module.scss';
import MetaData from '../components/Layout/MetaData';
import NavBar from '../components/NavBar/NavBar';
import styles from './404.module.scss';
import Footer from '../components/Footer/Footer';
import cameleon from 'aphrodite-shared/img/cameleon.png';

const Page404 = () => (
    <div className={iwocaPageStyles.Page}>
        <MetaData
            metaData={{
                titleTag: '404 Not found | iwoca',
                metaDescription:
                    "Oops, we got a 404 error – that means we couldn't find the page. Go back to iwoca.co.uk and try browsing from there.",
                allowSearchEngineScraping: false,
                url: '/404'
            }}
        />

        <NavBar />
        <div className={styles.fourOhFour}>
            <div className={styles.topHalf}>
                <h1 className={styles.title}>404 error</h1>
                <h2 className={styles.subTitle}>
                    Can’t find what you’re looking for?
                </h2>
            </div>
            <div className={styles.bottomHalf}>
                <p className={styles.bottomTitles}>Try these instead?</p>
                <a
                    className={styles.links}
                    title="iwoca – Homepage"
                    href="https://www.iwoca.co.uk/">
                    Homepage
                </a>
                <a
                    className={styles.links}
                    title="iwoca - Support centre"
                    href="https://support.iwoca.co.uk/en/">
                    Support centre
                </a>
                <a
                    className={styles.links}
                    title="iwocaPay"
                    href="https://www.iwoca.co.uk/iwocapay-sellers/">
                    iwocaPay
                </a>
                <div className={styles.bottomCircle}>
                    <img
                        className={styles.cameleon}
                        src={cameleon}
                        alt="cameleon"
                    />
                </div>
            </div>
        </div>
        <Footer />
    </div>
);

export default Page404;
